<template>
  <div class="patientDetails-box">
    <div class="box-title">
      <span class="title-one">用户 / </span>
      <span class="title-two">患者详情</span>
    </div>
    <div class="box-patientBasicInfo">
      <p class="title">
        患者基本信息
        <el-button
          v-if="this.$route.query.patientId"
          type="text"
          @click="onEditUserInfo"
          class="edit-btn">
          编辑
          <i class="el-icon-edit-outline"></i>
        </el-button>
        <el-button
          v-if="isMore"
          type="text"
          @click="onAll"
          class="edit-btn">
          查看全部
          <i class="el-icon-edit-outline"></i>
        </el-button>
      </p>
      <el-descriptions :column="3">
        <el-descriptions-item label="患者姓名">
          <span v-if="tableData.name">
            {{tableData.name}}
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="性别">
          <span v-if="tableData.gender">{{tableData.gender === 1 ? '男':'女'}}</span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="年龄">
          <span v-if="tableData.age">{{tableData.age}}</span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="身份证">
          <span v-if="tableData.idNumber">
            {{tableData.idNumber}}
            <el-button v-if="tableData.idNumber.indexOf('*') !== -1" type="text" class="el-icon-view" @click="onPlaintext('patientIdNumber')"></el-button>
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="地区">
          <span v-if="tableData.province && tableData.city && tableData.district">
            {{tableData.province + tableData.city + tableData.district}}
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="住址">
          <span v-if="tableData.addressDetail">
            {{tableData.addressDetail}}
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="ICM术后预留手机号">
          <span v-if="tableData.icmReservedPhone">
            {{tableData.icmReservedPhone}}
            <el-button v-if="tableData.icmReservedPhone.indexOf('*')!== -1" type="text" class="el-icon-view" @click="onPlaintext('patientIcmPhone')"></el-button>
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div v-if="this.$route.query.patientId" class="box-DeviceInfo">
      <p class="title">
        患者的设备信息
      </p>
      <div class="tables-container">
        <ICMTable v-if="!isEmptyIcmData" :icm-device="icmData" :is-loading="isIcmDataLoading" />
        <div v-else>
          无
        </div>
        <HomeBTTable v-if="!isEmptyIcmData" :home-bt-device="homeBtData" @updateStatus="updateGwStatus" :is-loading="isHomeBtDataLoading"/>
      </div>
    </div>

    <div v-if="this.$route.query.patientId" class="box-familyInfo">
      <p class="title">
        患者的家属信息
      </p>
      <el-table
        :row-style="{height:'35px'}"
        :cell-style="{padding:'3px'}"
        :data="usersList">
        <el-table-column
          align="center"
          fixed
          label="序号"
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          align="center"
          label="家属姓名">
          <template slot-scope="scope">
            <el-button type="text" @click="onClickPatient1(scope.row)">
              <span v-if="scope.row.name" >{{scope.row.name}}</span>
              <span v-else>-</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="etiology"
          label="性别">
          <template slot-scope="scope">
            <span v-if="scope.row.gender" >{{scope.row.gender === 1? '男':'女'}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="我是患者的">
          <template slot-scope="scope">
            <span v-if="scope.row.relationType" >{{scope.row.relationType}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="etiology"
          label="注册来源">
          <template slot-scope="scope">
            <span v-if="scope.row.appName"> {{ scope.row.appName }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="随访手机号">
          <template slot-scope="scope">
            <span v-if="scope.row.followUpPhone" >{{scope.row.followUpPhone}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="HFM手机号">
          <template slot-scope="scope">
            <span v-if="scope.row.hfmPhone" >{{scope.row.hfmPhone}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="ICM手机号">
          <template slot-scope="scope">
            <span v-if="scope.row.icmPhone" >{{scope.row.icmPhone}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="绑定状态">
          <template>
            <span>绑定中</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <EditUserDetails
    :visible.sync="isShowEdit"
    :tableData="tableData"
    @editPatientInfo="editPatientInfo"
    />
    <IDAuth
    :visible.sync="isShowIDAuth"
    :tableData="tableData"
    :dataName="dataName"
    @sensitiveData="sensitiveData"/>
    <AllInfo
    :visible.sync="isShowAllInfo"
    :userBasicInfo="userBasicInfo"
    />
  </div>
</template>
<script>
import EditUserDetails from './editUserDetails.vue'
import {
  getPatientInfo,
  getUserInfo,
  getUserSensitiveInfo,
  getPatientSensitiveInfo,
  getUserDetail,
  getPatientDetail,
  getPatientIcmDetail, getPatientGatewayDetail
} from '@/serve/module/userManage'
// import PartTitle from '@/components/Part'
import IDAuth from './IDAuth.vue'
import AllInfo from './allInfo.vue'
import ICMTable from './ICMTable.vue'
import HomeBTTable from './HomeBTTable.vue'

const moment = require('moment')
export default {
  components: {
    // PartTitle
    EditUserDetails,
    IDAuth,
    AllInfo,
    ICMTable,
    HomeBTTable
  },
  data () {
    return {
      moment: moment,

      userInfo: {},
      tableData: {},
      usersList: [],
      patientsList: [],

      isShowEdit: false,
      isShowIDAuth: false,
      isShowAllInfo: false,

      dataName: '',
      isMore: '',
      userBasicInfo: [],

      isIcmDataLoading: false,
      icmData: {}, /* ICM设备数据 */
      isHomeBtDataLoading: false,
      homeBtData: {} /* 家用蓝牙数据传输器数据 */

    }
  },
  created () {
    window.addEventListener('popstate', this.popstate, false)
    if (this.$route.query.patientId) {
      // 患者
      this.getPatientInfo()
      this.getPatientDetail()
      this.getPatientIcmDetail()
      this.getPatientGatewayDetail()
    } else {
      // 用户
      this.getUserInfo()
      this.getUserDetail()
    }
  },
  destroyed () {
    window.removeEventListener('popstate', this.popstate, false)
  },
  computed: {
    isEmptyIcmData () {
      return Object.keys(this.icmData).length === 0
    }
  },
  methods: {
    popstate () {
      console.log('发生跳转')
      if (this.$route.query.patientId) {
        this.getPatientInfo()
      } else {
        this.getUserInfo()
      }
    },
    getPatientInfo () {
      let params = {}
      if (this.$route.query.relationUserId) {
        params = {
          patientId: this.$route.query.patientId,
          relationUserId: this.$route.query.relationUserId
        }
      } else {
        params = {
          patientId: this.$route.query.patientId
        }
      }
      getPatientInfo(params).then(res => {
        if (res.data) {
          this.tableData = res.data
          this.tableData.userIcmPhone = res.data.icmPhone
          this.usersList = res.data.users
          this.userBasicInfo = []
          this.isMore = res.data.isMore
        }
      })
    },
    getUserInfo () {
      getUserInfo({
        userId: this.$route.query.userId,
        bizType: this.$route.query.bizType
      }).then(res => {
        if (res) {
          this.tableData = res.data
          this.tableData.userIcmPhone = res.data.icmPhone
          this.patientsList = res.data.patients
          this.isMore = res.data.isMore
        }
      })
    },
    getUserDetail () {
      getUserDetail({
        userId: this.$route.query.userId,
        bizType: this.$route.query.bizType
      }).then(res => {
        if (res) {
          console.log('用户更多列表：', res.data)
          this.userBasicInfo = res.data
        }
      })
    },
    async getPatientDetail () {
      getPatientDetail({
        patientId: this.$route.query.patientId
      }).then(res => {
        if (res) {
          console.log('患者更多列表：', res.data)
          this.userBasicInfo = res.data
        }
      })
    },
    async getPatientIcmDetail () {
      this.isIcmDataLoading = true
      try {
        getPatientIcmDetail({
          patientId: this.$route.query.patientId
        }).then(res => {
          if (res) {
            console.log('患者ICM设备信息：', res.data)
            if (res.data.deviceSn !== null) {
              this.icmData = res.data
            }
          }
        })
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.isIcmDataLoading = false
      }
    },
    async getPatientGatewayDetail () {
      this.isHomeBtDataLoading = true
      try {
        getPatientGatewayDetail({
          patientId: this.$route.query.patientId
        }).then(res => {
          if (res) {
            console.log('患者绑定的网关信息：', res.data)
            if (res.data.gatewaySn !== null) {
              this.homeBtData = res.data
            }
          }
        })
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        this.isHomeBtDataLoading = false
      }
    },
    updateGwStatus () {
      this.getPatientGatewayDetail()
    },
    onClickPatient (row) {
      this.$router.push({
        name: 'patientDetails',
        query: {
          patientId: row.id,
          relationUserId: row.relationUserId
        }
      })
      getPatientInfo({
        patientId: this.$route.query.patientId
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.data
          this.tableData.userIcmPhone = res.data.icmPhone
          this.usersList = res.data.users
          this.isMore = res.data.isMore
        } else {
          this.$message.error(res.message)
        }
      })
    },
    onClickPatient1 (row) {
      this.$router.push({
        name: 'userDetails',
        query: {
          userId: row.id
        }
      })
      getUserInfo({
        userId: this.$route.query.userId
      }).then(res => {
        if (res) {
          this.tableData = res.data
          this.tableData.userIcmPhone = res.data.icmPhone
          this.patientsList = res.data.patients
          this.userBasicInfo = []
          this.isMore = res.data.isMore
        }
      })
    },
    onEditUserInfo () {
      this.isShowEdit = true
    },
    onAll () {
      this.isShowAllInfo = true
      if (this.$route.query.patientId) {
        this.getPatientDetail()
      } else {
        this.getUserDetail()
      }
    },
    onPlaintext (e) {
      if (this.$route.query.patientId) {
        getPatientSensitiveInfo({
          authCode: '',
          id: this.tableData.id,
          dataName: e
        }).then(res => {
          if (res.code === 2003) {
            console.log('patient-result:', res.data)
            this.$confirm('此功能打开后，患者相关的敏感信息会明文显示，请注意数据安全，确定要开启此功能吗？', '系统提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.isShowIDAuth = true
              this.dataName = e
            }).catch(() => {
              //
            })
          } else {
            if (res.data.name === 'userFollowUpPhone') {
              this.tableData.followUpPhone = res.data.value
            } else if (res.data.name === 'userHfmPhone') {
              this.tableData.hfmPhone = res.data.value
            } else if (res.data.name === 'patientIdNumber') {
              this.tableData.idNumber = res.data.value
            } else if (res.data.name === 'userIcmPhone') {
              this.tableData.userIcmPhone = res.data.value
              this.$forceUpdate()
            } else if (res.data.name === 'patientIcmPhone') {
              this.tableData.icmReservedPhone = res.data.value
            }
          }
        }).catch(() => {
          this.$message.error('网络错误')
        })
      } else {
        getUserSensitiveInfo({
          authCode: '',
          id: this.tableData.id,
          dataName: e
        }).then(res => {
          if (res.code === 2003) {
            console.log('user-result:', res.data)
            this.$confirm('此功能打开后，患者相关的敏感信息会明文显示，请注意数据安全，确定要开启此功能吗？', '系统提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.dataName = e
              this.isShowIDAuth = true
            }).catch(() => {
              //
            })
          } else {
            if (res.data.name === 'userFollowUpPhone') {
              this.tableData.followUpPhone = res.data.value
            } else if (res.data.name === 'userHfmPhone') {
              this.tableData.hfmPhone = res.data.value
            } else if (res.data.name === 'patientIdNumber') {
              this.tableData.idNumber = res.data.value
            } else if (res.data.name === 'userIcmPhone') {
              this.tableData.userIcmPhone = res.data.value
              this.$forceUpdate()
            } else if (res.data.name === 'patientIcmPhone') {
              this.tableData.icmReservedPhone = res.data.value
            }
          }
        }).catch(() => {
          this.$message.error('网络错误')
        })
      }
    },
    sensitiveData (e) {
      if (e.name === 'userFollowUpPhone') {
        this.tableData.followUpPhone = e.value
      } else if (e.name === 'userHfmPhone') {
        this.tableData.hfmPhone = e.value
      } else if (e.name === 'patientIdNumber') {
        this.tableData.idNumber = e.value
      } else if (e.name === 'userIcmPhone') {
        this.tableData.userIcmPhone = e.value
      } else if (e.name === 'patientIcmPhone') {
        this.tableData.icmReservedPhone = e.value
      }
    },
    editPatientInfo () {
      this.getPatientInfo()
    }
  }
}
</script>
<style scoped lang="scss">
.patientDetails-box {
  .box-title {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    padding-left: 24px;
    gap: 5px;
    font-weight: 400;
    font-size: 14px;
    .title-one {
      color: #909399;
    }
    .title-two {
      color: #131414;
    }
  }
  .box-patientBasicInfo,.box-DeviceInfo, .box-familyInfo {
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
    .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0F1114;
    margin-bottom: 12px;
  }
  }
  .box-patientBasicInfo {
    width: 900px;
  }
}

.edit-btn {
  margin-left: 10px;
}

.el-icon-view {
  padding: 0px 5px;
  height: 21px;
  line-height: 21px;
}

.tables-container {
  display: flex; /* 使用Flex布局让两个表格并排显示 */
  justify-content: space-between; /* 控制表格间距 */
  gap: 20px;
}
</style>
